import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root:{
		backgroundImage: `url("/assets/images/fondo-nosotros-credito.svg")`,
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover'
	},
	title:{
		fontSize: 50,
		lineHeight: "54px",
		fontWeight: 'bold',
		marginBottom: 60,
		marginTop: 140,
		textAlign: 'center',
		padding: "0 221px",
		[theme.breakpoints.down('sm')]:{
			fontSize: 32,
			lineHeight: "40px",
			padding: "0 16px",

		}
	},
	iconContainer:{
		background: theme.palette.primary.main,
		width: 100,
		height: 100,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '50%'
	}, 
	icon:{
		width: 48,
		height: 48,
	},
	iconDescription:{
		fontSize: 24,
		lineHeight: "32px",
		width: 488,
		letterSpacing: "0.15px",
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			fontSize: 20,
			lineHeight: "32px",
			width: '100%',
			padding: '0 16px',
			boxSizing: 'border-box'
		}
	},
	descriptionContainer:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		gap:24,
		
	},
	container:{
		gap: "184px",
		marginBottom: 172,
		[theme.breakpoints.down('sm')]:{
			gap: "60px",
			marginBottom: 90,


		}
	}
})) 