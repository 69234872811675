import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from "./Location.styles";

export function Location() {
	const classes = useStyles()
	return (
		<Grid
			container
			data-sal="slide-up"
			data-sal-delay="0"
			data-sal-duration="1700"
			data-sal-easing="ease"
		>
			<Grid item container justifyContent="center">
				<Typography
					className={classes.title}
					color="primary"
				>
					¿Dónde nos encontramos?
				</Typography>
			</Grid>
			<Grid item container justifyContent="center" className={classes.mapContainer} >
				<img
					className={classes.mapa}
					src="/assets/images/mapa.svg"
					alt="Mapa de México"
				// data-sal="slide-right"
				// data-sal-delay="0"
				// data-sal-duration="1700"
				// data-sal-easing="ease"
				/>
				<Typography
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1700"
					// data-sal-easing="ease"
					className={classes.mapDescription}>
					Hoy nos encontramos en <strong> Monterrey, Nuevo León, </strong> mañana muy cerca de ti...
				</Typography>
			</Grid>
		</Grid>
	)
}