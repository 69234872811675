import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {

	},
	mapa: {
		width: 577,
		height: 394,
		[theme.breakpoints.down('sm')]: {
			width: 342,
			height: 215,
		},
		[theme.breakpoints.down(321)]: {

			width: 300,

		},
	},

	title: {
		fontSize: 50,
		lineHeight: "54px",
		marginTop: 90,
		marginBottom: 50,
		fontWeight: 'bold',
		[theme.breakpoints.down('sm')]: {
			fontSize: 32,
			lineHeight: "40px",
			textAlign: 'center',
			marginBottom: 91,
			marginTop: 80,

		},

	},
	mapContainer: {
		position: 'relative',
		marginBottom: 82
	},
	mapDescription: {
		position: 'absolute',
		width: 448,
		fontSize: 18,
		lineHeight: "24px",
		top: "20%",
		left: "55%",
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,
			width: 164,
			top: "-55px",
			right: "0",


		},
		[theme.breakpoints.down(321)]: {
			fontSize: 12,
			width: 127,
			top: "-30px",


		},

	}
}))