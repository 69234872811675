import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		backgroundImage: `url("/assets/images/fondo-nosotros-hero.webp")`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		minHeight: 'calc(100vh - 72px)',
		maxHeight: 'calc(100vh - 72px)',
		backgroundPositionY: 'bottom',
	},
	title: {
		fontSize: 60,
		lineHeight: '76px',
		fontWeight: 'bold',
		color: "#007A4C",
		textAlign: 'center',
		marginTop: 293,
		marginBottom: 421,
		width: 955,
		[theme.breakpoints.down('sm')]: {
			fontSize: 38,
			lineHeight: '40px',
			marginTop: 90,
			marginBottom: 532,
			padding: '0 16px',
			width: '100%'

		},
		[theme.breakpoints.between('sm', 'md')]: {
			marginTop: 234,

		},

	}
}))