import React from 'react'
import { Grid, Typography } from "@material-ui/core";
import { useStyles } from './Hero.styles';

export function HeroAboutUs() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root} >
			<Grid
				item
				container
				justifyContent="center"
				data-sal="slide-down"
				data-sal-delay="0"
				data-sal-duration="1000"
				data-sal-easing="ease"
			>
				<Typography className={classes.title}>Hacemos fácil lo que siempre había sido complicado</Typography>
			</Grid>
		</Grid>
	)
}