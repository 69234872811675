import { Grid, Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import React from "react";
import { useStyles } from "./Description.styles";

export function Description() {
	const classes = useStyles()
	return (
		<Grid container className={classes.root}>
			<Grid
				item
				container
				justifyContent="center"
				// data-sal="slide-down"
				// data-sal-delay="0"
				// data-sal-duration="1000"
				// data-sal-easing="ease"
				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.1, }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				<Typography color="primary" className={classes.title}>Estamos democratizando el crédito hipotecario:</Typography>
			</Grid>
			<Grid item container justifyContent="center" className={classes.container}>
				<Grid
					item
					className={classes.descriptionContainer}
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.1, }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
				>
					<div className={classes.iconContainer}>
						<img
							className={classes.icon}
							src="/assets/images/ayudar-icono.svg"
							alt="Queremos ayudar al mayor número de familias a tener la casa de sus sueños."
						/>
					</div>
					<Typography className={classes.iconDescription}>
						Queremos ayudar al mayor número de familias a tener la casa de sus sueños.
					</Typography>
				</Grid>
				<Grid
					item
					className={classes.descriptionContainer}
					// data-sal="slide-up"
					// data-sal-delay="0"
					// data-sal-duration="1000"
					// data-sal-easing="ease"
					initial={{ opacity: 0, y: 100 }}
					transition={{ duration: 1.1, }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					component={motion.div}
				>
					<div className={classes.iconContainer}>
						<img
							className={classes.icon}
							src="/assets/images/experiencia-icono.svg"
							alt="Creemos que la experiencia de solicitar un crédito hipotecario no tiene que ser tediosa y cansada."
						/>
					</div>
					<Typography className={classes.iconDescription} >
						Creemos que la experiencia de solicitar un crédito hipotecario no tiene que ser tediosa y cansada.
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	)
}