import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		background: "rgba(117, 139, 183, 0.04)",
		padding: '0 16px',
		minHeight: 795,
		maxHeight: 795,
		[theme.breakpoints.down('sm')]: {
			minHeight: 'unset',
			maxHeight: 'unset',
			maxWidth: '100%'
		},
	},
	titleContainer: {
		//maxHeight: 290,

	},
	title: {
		fontSize: 50,
		lineHeight: "54px",
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: 90,
		marginBottom: 60,
		[theme.breakpoints.down('sm')]: {
			fontSize: 32,
			lineHeight: "40px",
			marginTop: 80,
			marginBottom: 40,


		}
	},
	membersContainer: {
		padding: "0 54px",
		gap: 39,
		marginBottom: 40,
		justifyContent: 'center',
		minHeight: 496,
		display: 'grid',
		gridTemplateColumns: 'repeat(6, 180px)',
		[theme.breakpoints.down('sm')]: {
			padding: "0",
			gap: 16,
			minHeight: 'unset',
			display: 'flex',
			justifyContent: 'flex-start',
			flexDirection: 'column',

		},
	},
	memberContainer: {
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			flexDirection: 'row',
			gap: 8,
			alignItems: 'center'
		}
	},
	profilePictureContainer: {
		width: 184,
		height: 184,
		overflow: 'hidden',
		[theme.breakpoints.down('sm')]: {
			width: 80,
			height: 80,
			borderRadius: 4,
		}
	},
	profilePicture: {
		width: 184,
		//height: '100%',
		[theme.breakpoints.down('sm')]: {
			width: 80,
			//height: '100%',
			borderRadius: 4,
		}
	},
	name: {
		fontSize: 14,
		fontWeight: 'bold',
		letterSpacing: '0.15px',
		marginTop: 4,
		marginBottom: 2,
		[theme.breakpoints.down('sm')]: {
			fontSize: 16,

		}
	},
	position: {
		fontSize: 12,
		letterSpacing: '0.15px',
		[theme.breakpoints.down('sm')]: {
			fontSize: 14,

		}
	},

	slideInRight: {
		"-webkit-animation": "slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
		animation: "slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both",
	},

	"@-webkit-keyframes slide-in-right": {
		"0%": {
			"-webkit-transform": " translateX(1000px)",
			"transform": "translateX(1000px)",
			"opacity": "0",
		},
		"100%": {
			" -webkit-transform": "translateX(0)",
			"transform": "translateX(0)",
			"opacity": 1,
		}
	},
	"@keyframes slide-in-right": {
		"0%": {
			" -webkit-transform": "translateX(1000px)",
			transform: "translateX(1000px)",
			opacity: 0,
		},
		"100%": {
			" -webkit-transform": "translateX(0)",
			transform: "translateX(0)",
			opacity: 1,
		}
	}


}))