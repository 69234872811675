import React from "react"
import { Testimonial, Footer } from "../components"
import { Description, HeroAboutUs, Location, TeamMembers } from "../components/AboutUs"

import { Layout } from "../layout"
function AboutUs({ location }: any) {
	return (
		<Layout title="Kredi | Nosotros" location={location}>
			<HeroAboutUs />
			<Description />
			<Testimonial />
			<TeamMembers />
			<Location />
			<Footer title="Es momento de ser parte de la nueva forma de adquirir un crédito hipotecario." />
		</Layout>
	)
}

export default AboutUs