import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import { Pagination } from "../../";
import { useStyles } from "./TeamMembers.styles";

interface Member {
	node: {
		name: string,
		area: string,
		profile_picture: string
	}
}

export function TeamMembers() {
	const [page, setPage] = useState(1)
	const data = useStaticQuery(graphql`
		query members{
			allTeamJson {
			  edges{
				  node{
					area
					name
					profile_picture
				  }
			  }
			}
		}
  	`)
	const theme = useTheme()
	const classes = useStyles()
	const perPage = 12
	const isSM = useMediaQuery(theme.breakpoints.down('sm'))

	return (
		<Grid container justifyContent="center" id="team" className={classes.root}>
			<Grid item className={classes.titleContainer}>
				<Typography className={classes.title} color="primary">Conoce al equipo Kredi</Typography>
			</Grid>
			<Grid
				item
				container
				className={classes.membersContainer}
				initial={{ opacity: 0, y: 100 }}
				transition={{ duration: 1.1 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				component={motion.div}
			>
				{
					data?.allTeamJson?.edges?.slice(perPage * (page - 1), perPage * page).map(({ node: { name, area, profile_picture } }: Member) => (
						<Grid key={name} item className={clsx(classes.memberContainer, { [classes.slideInRight]: !isSM })}>
							<div className={classes.profilePictureContainer}>
								<img
									className={classes.profilePicture}
									src={profile_picture}
									alt={name}
								/>
							</div>
							<div>
								<Typography className={classes.name}>{name}</Typography>
								<Typography className={classes.position}>{area}</Typography>

							</div>
						</Grid>
					))
				}


			</Grid>
			<Pagination perPage={perPage} total={data?.allTeamJson?.edges.length} page={page} handleChangePage={setPage} />
		</Grid>
	)
}